@import url(https://www.1001fonts.com/royal-crescent-font.html);
@import url(https://www.1001fonts.com/royal-crescent-font.html);
h1, h2, h5, p {
    color: white;
    font-family: royal crescent;
    /* text-shadow: 1px 1px black; */
    text-align:center;
}

p {
    font-size: 10px;
}

html, body {
    width:100%; 
    overflow-x:hidden;
    background-color:black;
}

body, .smooth-container {
    scroll-behavior: smooth;
}
.nav-menu {
    background-color: white;
    background: linear-gradient( rgba(0, 0, 0, 0.2),
     rgba(0, 0, 0, 0.2) );
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      width: 100%;

    font-family: 'Times New Roman', Times, serif;
}

.icon-resume {
    color:white;
}

@media (max-width: 768px) {
    #mobile {
        height: 60px;
    }

    .icon-resume {
        color:rgba(0, 0, 0, 0.671);
    }
}
.header {  
    width: 100%;
    background-color: black;
    background: linear-gradient( rgba(0, 0, 0, 0.2),
     rgba(0, 0, 0, 0.2) ),
      url(/static/media/fisheye.4e00940d.jpg);
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      min-height: 120vh;
    }

.name {
    color: white;
    font-size: 50px;
    font-family: royal crescent;
    text-shadow: 1px 1px black;
    text-align:center;
    min-height:60vh;
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content:center;
    align-items:center;

}

.roles {
    color: white;
    font-family: royal crescent;
    display:flex;
    justify-content:space-evenly;
    /* padding: 10px; */
}

.roles p {
    display:flex;
    justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width:786px){
    .header {
        margin: 0 auto;
        background: url(/static/media/building_view.9391d133.jpg);
        background-position: center center;
        background-size: 100% 100%;
    }
    

    .roles {
        color:black;
        
        font-family: royal crescent;
        display:flex;
        justify-content:space-evenly;
        /* padding: 10px; */
    }
    .roles a {
        font-size: 20px;
        text-shadow: -1px 0px white,
		1px -1px black,
		-1px 0px #000,
		1px 0px  black;
    }
}
#role {
  background-color: rgb(9, 110, 110);
  border-color: tan;
  border-radius: 5px;
  width: 10rem;
  height: 4rem;
}


.role-description {
  padding:50px;
}

.role-description p {
  color: black;
  font-family: 'Monaco';
  font-size: 12px;
}

p a {
    color:white;
    font-size: 12px;
}

.effect-shine {
  background-image: linear-gradient(90deg, white 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, white 75%);
  background-position: right center;
  background-size: 400% auto;
  -webkit-background-clip: text;
  background-clip: text;
  color: white;
  -webkit-text-fill-color: transparent;
  transition: color 200ms linear;
}

.effect-shine:hover {
  background-position: left center;
  color: #ef9671;
  transition: background-position 2000ms ease-out;
}

@media (max-width: 786px) {
  #role {
  background-color: rgb(43, 96, 196);
  }
}
#about {
    background: linear-gradient( rgba(1, 7, 7, 0.897),
    rgba(0, 0, 0, 0.897) ),
     url(/static/media/highrise.8d7cafea.jpg);
     background-size:cover;
     background-position: center;
     background-attachment: fixed;
     width: 100%;
    display: flex;
    flex-direction: column;
    /* min-height: 85vh; */
    padding: 12px; 
}

#about h2 {
    margin-top:3rem;
    padding:30px;
}
.ui.container.about {
    padding: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.about-header h2{
    color:white;
    text-shadow: 3px 3px black;
    font-family: royal crescent;
    padding:10px;
}

.about-image h5{
    font-family: 'Cochin';
    padding: 5px;
}

.about-image img{
    border: 0.2rem;
    border-radius: 0.5rem;
    opacity: 0.7;
}

.about-image img:hover{
    opacity: 1;
}

.about-p p {
    font-family: 'Cochin';
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.even {
    flex-direction: row-reverse;
}

@media (max-width: 768px) {
    #about {
        margin: 0 auto;
        background: linear-gradient( rgba(1, 7, 7, 0.897),
        #1B1C1D);
         background-position: center center;
        background-size: cover;
        -o-background-size: cover;
    }
    .about {
        display:flex;
        justify-content: center;
        flex-wrap: inherit;
    }
    .about-image {
        padding: 20px 40px 10px 40px;
        display:flex; 
        flex-direction: column;
        align-items: center;
    }
    .about-p p {
        display:flex;
        padding: 20px;
    }
}
#projects {
    display:flex;
    flex-direction: column;
    background: linear-gradient(
        rgb(24, 67, 71),   rgb(0, 0, 0));
    /* min-height: 85vh; */
    width: 100%;
    background-color: rgba(22, 21, 21, 0.726);
    opacity: 0.9;
    margin: 0 auto;
    padding: 12px;

}

#projects h2 {
    font-family: royal crescent;
    margin-top:3rem;
    padding: 30px;
    color: white;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.411);

}

#title {
    font-family: Cochin;
    text-shadow: 1px 1px #e6960221;
}

#description {
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: Cochin;
    font-size: 14px;
    margin:15px;
}

.portfolio-img {
    height:150px;
    width: 300px;
    padding: 10px;
    margin:auto;
    border-radius: .5rem;
    background-size:cover;
    background-position: center;
    border: .1rem solid black;
}

.projects {
    display: flex;
    justify-content: space-evenly;

  } 
  
.project {
    margin:15px auto;
    padding: 10px; 
    border: .4rem solid rgba(141, 93, 38, 0.986);
    border-radius: 1rem;
    width: 100%;
    color: white;
    background: rgba(0,0,0,0.4);
    text-shadow: 0 0 .9rem black;
}

.project:hover {
    border: .4rem solid rgba(248, 166, 73, 0.986);
}


.cubatoriano {
    background-image: url(/static/media/cubatoriano.ba5d5195.jpg);
}

.overtime {
    background-image: url(/static/media/overtime.c526d6c3.jpeg);
}

.cocktailparty {
    background-image: url(/static/media/cocktailparty.6cd3054b.jpg);
}

.random {
    background-image: url(/static/media/random.52eca874.jpg);
}

#contact {
    background: linear-gradient( rgba(3, 22, 22, 0.5),
     rgba(0, 0, 0, 0.89) ),
      url(/static/media/darkcode.1cbfa434.jpg);
      background-position: center center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      width: 100%;
    padding: 12px;
}


#contact h2{
    color: rgba(248, 166, 73, 0.986);
    font-family: royal crescent;
    text-shadow: 3px 3px black;
    text-align:center;
    padding:30px;
    margin-top:3rem;
}

.columns {
    display: flex;
    justify-content: center;
    align-items:center;
}

.social-link {
    padding: 10px;
    color: rgba(248, 166, 73, 0.986);
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-link:hover {
    color: white;
}

.resume {
    color: rgba(242, 176, 56, 1.0);
    text-shadow: 1px 1px black;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
.footer {
    background-color:#1B1C1D;
}

@media (max-width:768px) {
    #contact {
        margin: 0 auto;
        background-color:#1B1C1D;
        background-position: center center;
    }
    .footer {
        padding: 10px;
        background-color:#1B1C1D;
        

    }
    .columns {
        display: flex;
        justify-content: space-evenly;
        align-items:center;
    }
}
