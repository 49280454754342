.header {  
    width: 100%;
    background-color: black;
    background: linear-gradient( rgba(0, 0, 0, 0.2),
     rgba(0, 0, 0, 0.2) ),
      url(../Media/fisheye.jpg);
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      min-height: 120vh;
    }

.name {
    color: white;
    font-size: 50px;
    font-family: royal crescent;
    text-shadow: 1px 1px black;
    text-align:center;
    min-height:60vh;
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content:center;
    align-items:center;

}

.roles {
    color: white;
    font-family: royal crescent;
    display:flex;
    justify-content:space-evenly;
    /* padding: 10px; */
}

.roles p {
    display:flex;
    justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width:786px){
    .header {
        margin: 0 auto;
        background: url('../Media/building_view.jpg');
        background-position: center center;
        background-size: 100% 100%;
    }
    

    .roles {
        color:black;
        
        font-family: royal crescent;
        display:flex;
        justify-content:space-evenly;
        /* padding: 10px; */
    }
    .roles a {
        font-size: 20px;
        text-shadow: -1px 0px white,
		1px -1px black,
		-1px 0px #000,
		1px 0px  black;
    }
}