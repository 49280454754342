@import url(https://www.1001fonts.com/royal-crescent-font.html);

#projects {
    display:flex;
    flex-direction: column;
    background: linear-gradient(
        rgb(24, 67, 71),   rgb(0, 0, 0));
    /* min-height: 85vh; */
    width: 100%;
    background-color: rgba(22, 21, 21, 0.726);
    opacity: 0.9;
    margin: 0 auto;
    padding: 12px;

}

#projects h2 {
    font-family: royal crescent;
    margin-top:3rem;
    padding: 30px;
    color: white;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.411);

}

#title {
    font-family: Cochin;
    text-shadow: 1px 1px #e6960221;
}

#description {
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: Cochin;
    font-size: 14px;
    margin:15px;
}

.portfolio-img {
    height:150px;
    width: 300px;
    padding: 10px;
    margin:auto;
    border-radius: .5rem;
    background-size:cover;
    background-position: center;
    border: .1rem solid black;
}

.projects {
    display: flex;
    justify-content: space-evenly;

  } 
  
.project {
    margin:15px auto;
    padding: 10px; 
    border: .4rem solid rgba(141, 93, 38, 0.986);
    border-radius: 1rem;
    width: 100%;
    color: white;
    background: rgba(0,0,0,0.4);
    text-shadow: 0 0 .9rem black;
}

.project:hover {
    border: .4rem solid rgba(248, 166, 73, 0.986);
}


.cubatoriano {
    background-image: url(../Media/cubatoriano.jpg);
}

.overtime {
    background-image: url(../Media/overtime.jpeg);
}

.cocktailparty {
    background-image: url(../Media/cocktailparty.jpg);
}

.random {
    background-image: url(../Media/random.jpg);
}