.nav-menu {
    background-color: white;
    background: linear-gradient( rgba(0, 0, 0, 0.2),
     rgba(0, 0, 0, 0.2) );
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      width: 100%;

    font-family: 'Times New Roman', Times, serif;
}

.icon-resume {
    color:white;
}

@media (max-width: 768px) {
    #mobile {
        height: 60px;
    }

    .icon-resume {
        color:rgba(0, 0, 0, 0.671);
    }
}