#role {
  background-color: rgb(9, 110, 110);
  border-color: tan;
  border-radius: 5px;
  width: 10rem;
  height: 4rem;
}


.role-description {
  padding:50px;
}

.role-description p {
  color: black;
  font-family: 'Monaco';
  font-size: 12px;
}

p a {
    color:white;
    font-size: 12px;
}

.effect-shine {
  background-image: -webkit-linear-gradient(left, white 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, white 75%);
  background-image: linear-gradient(90deg, white 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, white 75%);
  background-position: right center;
  background-size: 400% auto;
  -webkit-background-clip: text;
  background-clip: text;
  color: white;
  -webkit-text-fill-color: transparent;
  transition: color 200ms linear;
}

.effect-shine:hover {
  background-position: left center;
  color: #ef9671;
  transition: background-position 2000ms ease-out;
}

@media (max-width: 786px) {
  #role {
  background-color: rgb(43, 96, 196);
  }
}