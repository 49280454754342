#about {
    background: linear-gradient( rgba(1, 7, 7, 0.897),
    rgba(0, 0, 0, 0.897) ),
     url(../Media/highrise.jpg);
     background-size:cover;
     background-position: center;
     background-attachment: fixed;
     width: 100%;
    display: flex;
    flex-direction: column;
    /* min-height: 85vh; */
    padding: 12px; 
}

#about h2 {
    margin-top:3rem;
    padding:30px;
}
.ui.container.about {
    padding: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.about-header h2{
    color:white;
    text-shadow: 3px 3px black;
    font-family: royal crescent;
    padding:10px;
}

.about-image h5{
    font-family: 'Cochin';
    padding: 5px;
}

.about-image img{
    border: 0.2rem;
    border-radius: 0.5rem;
    opacity: 0.7;
}

.about-image img:hover{
    opacity: 1;
}

.about-p p {
    font-family: 'Cochin';
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.even {
    flex-direction: row-reverse;
}

@media (max-width: 768px) {
    #about {
        margin: 0 auto;
        background: linear-gradient( rgba(1, 7, 7, 0.897),
        #1B1C1D);
         background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
    }
    .about {
        display:flex;
        justify-content: center;
        flex-wrap: inherit;
    }
    .about-image {
        padding: 20px 40px 10px 40px;
        display:flex; 
        flex-direction: column;
        align-items: center;
    }
    .about-p p {
        display:flex;
        padding: 20px;
    }
}