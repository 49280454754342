@import url(https://www.1001fonts.com/royal-crescent-font.html);

h1, h2, h5, p {
    color: white;
    font-family: royal crescent;
    /* text-shadow: 1px 1px black; */
    text-align:center;
}

p {
    font-size: 10px;
}

html, body {
    width:100%; 
    overflow-x:hidden;
    background-color:black;
}

body, .smooth-container {
    scroll-behavior: smooth;
}