
#contact {
    background: linear-gradient( rgba(3, 22, 22, 0.5),
     rgba(0, 0, 0, 0.89) ),
      url(../Media/darkcode.jpg);
      background-position: center center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size:cover;
      width: 100%;
    padding: 12px;
}


#contact h2{
    color: rgba(248, 166, 73, 0.986);
    font-family: royal crescent;
    text-shadow: 3px 3px black;
    text-align:center;
    padding:30px;
    margin-top:3rem;
}

.columns {
    display: flex;
    justify-content: center;
    align-items:center;
}

.social-link {
    padding: 10px;
    color: rgba(248, 166, 73, 0.986);
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-link:hover {
    color: white;
}

.resume {
    color: rgba(242, 176, 56, 1.0);
    text-shadow: 1px 1px black;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
.footer {
    background-color:#1B1C1D;
}

@media (max-width:768px) {
    #contact {
        margin: 0 auto;
        background-color:#1B1C1D;
        background-position: center center;
    }
    .footer {
        padding: 10px;
        background-color:#1B1C1D;
        

    }
    .columns {
        display: flex;
        justify-content: space-evenly;
        align-items:center;
    }
}